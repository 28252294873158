import classNames from 'classnames'
import React from 'react'
import Text, { type TextProps } from 'components/Text'
import styles from './Section.module.scss'

type Props = {
  children: React.ReactNode
  color?: 'blue' | 'purple' | 'grey' | 'white'
  size?: 'xlarge' | 'large' | 'medium' | 'small'
} & TextProps

export default function Section({
  children,
  className,
  color = 'white',
  size = 'medium',
  ...props
}: Props) {
  let textColor

  if (color === 'blue' || color === 'purple') {
    textColor = 'white'
  }

  return (
    <Text
      {...props}
      color={textColor}
      element="div"
      className={classNames(
        'Section',
        styles.this,
        color && styles[`this---${color}`],
        size && styles[`this---${size}`],
        className
      )}>
      <div className={styles.inner}>{children}</div>
    </Text>
  )
}
